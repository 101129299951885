@import "variables.css";

html,
#root {
  height: 100%;
  overflow: hidden;
}
body {
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100%;
  background-color: var(--page-bg-1);
}

.center {
  display: grid;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
}

/* Custom scroll bar css starts */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Corner where both X and Y scroll bar meets */
::-webkit-scrollbar-corner {
  background: none;
}
/* Custom scroll bar css ends */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 1.5px 11px;
}
.primary_button_style {
  background-color: var(--primary);
  color: white;
}
:where(.css-dev-only-do-not-override-1iacbu2).ant-notification
  .ant-notification-notice
  .ant-notification-notice-close {
  color: var(--close-icon);
}

:where(.css-dev-only-do-not-override-1iacbu2).ant-notification
  .ant-notification-notice
  .ant-notification-notice-close:hover {
  color: var(--close-icon);
}
.red {
  color: red !important;
}

.ant-spin-dot-item:nth-child(1) {
  background-color: rgb(12, 12, 224);
}
.ant-spin-dot-item:nth-child(2) {
  background-color: rgb(16, 241, 16);
}
.ant-spin-dot-item:nth-child(4) {
  background-color: rgb(255, 255, 28);
}
.ant-spin-dot-item:nth-child(3) {
  background-color: rgb(249, 5, 46);
}

.flex-space-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.border {
  border: 2px solid grey;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.height-100 {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.width-100 {
  width: 100% !important;
}

.font-size-12 {
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.pad-24-top {
  padding-top: 24px;
}

.pad-24-left {
  padding-left: 24px;
}

.pad-24-right {
  padding-right: 24px;
}

.pad-24-bottom {
  padding-bottom: 24px;
}

.pad-5-bottom {
  padding-bottom: 5px;
}

.divider-modal {
  margin: 18px 0px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.modal-desc-text {
  font-size: 16px;
  margin: 5px 0px 19px 25px;
  color: var(--black);
}
.fs_20 {
  font-size: 20px;
}
.color-black {
  color: black !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.smile-icon {
  color: blue;
}
.pr-10 {
  padding-right: 10px;
}
.p-4 {
  padding: 4px;
}
.m-5 {
  margin: 10px 0px;
}
